<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page
        back
    >
        <template
            slot="body"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="formModel"
                :rules="formRules"
            >
                <el-card
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>买家信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="买家手机:"
                            label-width="8em"
                        >
                            {{ buyer.mobile }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>开票信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            label="开票时间:"
                            label-width="8em"
                        >
                            {{ orderInvoice.invoiceTime | moment('YYYY-MM-DD HH:mm:ss') }}
                        </el-form-item>
                        <el-form-item
                            label="开票金额:"
                            label-width="8em"
                        >
                            {{ orderInvoice.invoiceAmount }}
                        </el-form-item>
                        <el-form-item
                            label="发票类型:"
                            label-width="8em"
                        >
                            {{ orderInvoice.invoiceTypeName }}
                        </el-form-item>
                        <el-form-item
                            label="发票抬头类型:"
                            label-width="8em"
                        >
                            {{ orderInvoice.invoiceTitleTypeName }}
                        </el-form-item>
                        <el-form-item
                            label="发票抬头:"
                            label-width="8em"
                        >
                            {{ orderInvoice.invoiceTitle }}
                        </el-form-item>
                        <el-form-item
                            label="纳税人识别号:"
                            label-width="8em"
                            v-if="orderInvoice.invoiceTitleType === 1"
                        >
                            {{ orderInvoice.taxIdentificationSn }}
                        </el-form-item>
                        <el-form-item
                            label="开户银行:"
                            label-width="8em"
                            v-if="orderInvoice.invoiceTitleType === 1"
                        >
                            {{ orderInvoice.bankName }}
                        </el-form-item>
                        <el-form-item
                            label="开户账号:"
                            label-width="8em"
                            v-if="orderInvoice.invoiceTitleType === 1"
                        >
                            {{ orderInvoice.bankAccount }}
                        </el-form-item>
                        <el-form-item
                            label="注册地址:"
                            label-width="8em"
                            v-if="orderInvoice.invoiceTitleType === 1"
                        >
                            {{ orderInvoice.registerAddress }}
                        </el-form-item>
                        <el-form-item
                            label="注册电话:"
                            label-width="8em"
                            v-if="orderInvoice.invoiceTitleType === 1"
                        >
                            {{ orderInvoice.registerTel }}
                        </el-form-item>
                        <el-form-item
                            label="接收邮箱:"
                            label-width="8em"
                        >
                            {{ orderInvoice.receiveEmail }}
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>订单信息</span>
                    </div>
                    <div class="item">
                        <el-table
                            stripe
                            border
                            size="small"
                            :data="orderList"
                            style="width: 100%"
                        >
                            <el-table-column type="expand">
                                <template slot-scope="ScopeOrder">
                                    <el-table
                                        stripe
                                        border
                                        size="small"
                                        :data="ScopeOrder.row.orderDetailList"
                                        style="width: 100%"
                                    >
                                        <el-table-column
                                            label="商品图片"
                                            width="120"
                                        >
                                            <template slot-scope="scope">
                                                <ImageList
                                                    :data="scope.row.specImageUrl"
                                                />
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="goodsName"
                                            label="商品名称"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="specDesc"
                                            label="商品规格"
                                            min-width="100"
                                        />
                                        <el-table-column
                                            prop="finalPrice"
                                            label="商品价格"
                                            min-width="50"
                                        />
                                        <el-table-column
                                            prop="quantity"
                                            label="数量"
                                            min-width="50"
                                        />
                                        <el-table-column
                                            prop="subtotalAmount"
                                            label="小计"
                                            min-width="50"
                                        />
                                    </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orderSn"
                                label="订单编号"
                                width="150"
                            >
                                <template slot-scope="scope">
                                    <ImageList
                                        class="dp-ib"
                                        :data="scope.row.buyerLevelIconUrl"
                                        item-width="20px"
                                    />
                                    {{ scope.row.orderSn }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="下单时间"
                                width="135"
                            >
                                <template slot-scope="scope">
                                    {{ scope.row.orderTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="payableAmount"
                                label="订单金额"
                                width="70"
                            >
                                <template
                                    slot-scope="scope"
                                >
                                    <el-popover
                                        trigger="hover"
                                    >
                                        <el-table :data="scope.row.amountInfoList">
                                            <el-table-column
                                                width="150px"
                                                property="label"
                                            />
                                            <el-table-column
                                                width="100px"
                                                property="value"
                                            />
                                        </el-table>
                                        <span
                                            slot="reference"
                                            class="tc-blue"
                                        >{{ scope.row.payableAmount }}</span>
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="orderSourceName"
                                label="订单来源"
                                width="70"
                            />
                            <el-table-column
                                prop="orderTypeName"
                                label="订单类型"
                                width="70"
                            />
                            <el-table-column
                                prop="buyerMsg"
                                label="买家留言"
                                min-width="100"
                            />
                            <el-table-column
                                label="扩展信息"
                                min-width="100"
                            >
                                <template
                                    slot-scope="scope"
                                    v-if="scope.row.extendFieldInfoList && scope.row.extendFieldInfoList.length != 0"
                                >
                                    <el-popover
                                        trigger="hover"
                                    >
                                        <el-table :data="scope.row.extendFieldInfoList">
                                            <el-table-column
                                                width="150px"
                                                property="label"
                                                label="字段名称"
                                            />
                                            <el-table-column
                                                width="200px"
                                                property="value"
                                                label="字段值"
                                            />
                                        </el-table>
                                        <span
                                            slot="reference"
                                            class="tc-blue"
                                        >{{ scope.row.extendFieldInfoList[0].label }}:{{ scope.row.extendFieldInfoList[0].value }}</span>
                                    </el-popover>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
                <el-card
                    size="small"
                    shadow="never"
                    class="ma-t"
                    v-if="orderInvoice.invoiceStatus === 10"
                >
                    <div
                        slot="header"
                        class="clearfix"
                    >
                        <span>审核信息</span>
                    </div>
                    <div class="item">
                        <el-form-item
                            prop="invoiceStatus"
                            label="审核是否通过:"
                            label-width="8em"
                        >
                            <el-select
                                v-model="formModel.invoiceStatus"
                                placeholder="请选择"
                            >
                                <el-option
                                    label="审核通过"
                                    value="100"
                                />
                                <el-option
                                    label="审核不通过"
                                    value="90"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            prop="failReason"
                            label="失败原因"
                            label-width="8em"
                            v-if="formModel.invoiceStatus == 90"
                        >
                            <el-input
                                type="textarea"
                                v-model="formModel.failReason"
                                auto-complete="off"
                            />
                        </el-form-item>
                        <el-form-item
                            label-width="8em"
                        >
                            <el-button
                                type="primary"
                                size="small"
                                @click="onConfirm()"
                            >
                                提交审核
                            </el-button>
                        </el-form-item>
                    </div>
                </el-card>
                <el-card
                    class="ma-t"
                    size="small"
                    shadow="never"
                >
                    <div
                        slot="header"
                        class="clearfix">
                        <span>操作日志</span>
                    </div>
                    <el-col :span="24">
                        <el-col :span="22">
                            <el-form-item
                                label-width="5em"
                            >
                                <!-- 表格 -->
                                <el-table
                                    stripe
                                    border
                                    size="small"
                                    :data="logList"
                                    style="width: 100%"
                                >
                                    <el-table-column
                                        prop="operateName"
                                        label="操作"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="operatorName"
                                        label="操作人"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        prop="operateNote"
                                        label="操作说明"
                                        min-width="100"
                                    />
                                    <el-table-column
                                        label="操作时间"
                                        width="135"
                                    >
                                        <template slot-scope="scope">
                                            {{ scope.row.operateTime | moment('YYYY-MM-DD HH:mm:ss') }}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                        prop="remarks"
                                        label="备注"
                                        min-width="100"
                                    />
                                </el-table>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-card>
                <el-col :span="12">
                    <el-form-item
                        label-width="8em"
                        class="ma-t"
                    >
                        <el-button
                            size="small"
                            @click="$router.back()"
                        >
                            返 回
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-form>
        </template>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';

export default {
    name: 'OrderInvoiceOfflineAuditDetail',
    mixins: [pagesMixin],
    data() {
        return {
            // 表单数据
            formModel: {
                id: '',
                invoiceStatus: '',
                failReason: '',
            },
            // 表单校验规则
            formRules: {
                invoiceStatus: {
                    required: true,
                    message: '请选择',
                },
                failReason: {
                    required: true,
                    message: '请输入失败原因',
                    trigger: 'blur',
                },
            },
            orderInvoice: {},
            buyer: {},
            orderList: [],
            logList: [],
        };
    },
    methods: {
        init() {
            this.$api.Ex.OrderInvoice.getDetail({ id: this.formModel.id }).then(json => {
                const res = json.data.data;
                this.orderInvoice = res.orderInvoice || {};
                this.buyer = res.buyer || {};
                this.logList = res.logList || [];
                this.orderList = res.orderList || [];
                this.orderList.forEach(item => {
                    const amountInfoList = [];
                    amountInfoList.push({
                        label: '商品总金额',
                        value: item.goodsAmount,
                    });
                    amountInfoList.push({
                        label: '商品总积分',
                        value: item.goodsPointNum,
                    });
                    amountInfoList.push({
                        label: '运费',
                        value: item.freight,
                    });
                    amountInfoList.push({
                        label: '包装费',
                        value: item.packingAmount,
                    });
                    amountInfoList.push({
                        label: '优惠券金额',
                        value: item.couponAmount,
                    });
                    amountInfoList.push({
                        label: '积分抵扣金额',
                        value: item.pointAmount,
                    });
                    amountInfoList.push({
                        label: '满额减免金额',
                        value: item.reduceAmount,
                    });
                    amountInfoList.push({
                        label: '总优惠金额',
                        value: item.totalDiscountAmount,
                    });
                    amountInfoList.push({
                        label: '保费金额',
                        value: item.goodsPremiumAmount,
                    });
                    amountInfoList.push({
                        label: '手续费',
                        value: item.paymentPoundage,
                    });
                    amountInfoList.push({
                        label: '订单金额',
                        value: item.payableAmount,
                    });
                    item.amountInfoList = amountInfoList;
                });
            });
        },
        onConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$confirm('是否确定提交审核！', '温馨提示', {
                    type: 'warning',
                }).then(() => {
                    this.$api.Ex.OrderInvoice.invoiceAudit({
                        ...this.formModel,
                    }).then(json => {
                        const res = json.data;
                        this.$message({
                            message: res.msg,
                            type: 'success',
                        });
                        this.init();
                    });
                });
            });
        },
    },
    created() {
        this.formModel.id = this.$route.params.id;
        if (this.formModel.id) {
            this.init();
        }
    },
};
</script>

<style lang="scss">
</style>
